import { registerApplication } from 'single-spa'
import { importSpa, matchRoute } from '../utils'
import { RegistrationFunc } from './registration'
import { spaList, spaRoutes } from './spaConfigs'

export const registerOrgLookupApp: RegistrationFunc = (globalCustomProps) => {
  const name = spaList['SPA-SSO-LOOKUP']
  registerApplication({
    name,
    activeWhen: ({ pathname }) =>
      matchRoute(spaRoutes[name], Object.values(spaRoutes), pathname),
    customProps: globalCustomProps,
    app: importSpa(name)
  })
}
