export const matchRoute = (
  spaRoute: string,
  spaRouteList: string[],
  path: string,
  useAsNoMatchFallback: boolean = false
) => {
  const trimmedPath = path.replace(/\/+$/, '')
  let pathMatched = trimmedPath === spaRoute

  if (!pathMatched && useAsNoMatchFallback) {
    pathMatched = !spaRouteList.includes(trimmedPath)
  }
  return pathMatched
}
