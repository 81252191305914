import i18next from 'i18next'
import { Intl } from '@toasttab/banquet-types'
import { Locale } from '@toasttab/buffet-pui-locale-utilities'

export async function getI18nProps(): Promise<Intl> {
  // TODO: theres no i18n for public-banquet-root and wex-banquet-root uses context
  // from the restaurant. Perhaps just take it from the browser locale

  return await i18next
    .init({
      lng: 'en-US',
      fallbackLng: 'en',
      debug: process.env.NODE_ENV === 'development',
      interpolation: {
        escapeValue: false
      },
      resources: {}
    })
    .then(() => ({
      language: 'en-US' as Locale,
      currency: 'USD' as Intl['currency'],
      i18next
    }))
}
