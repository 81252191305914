import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'focus-visible'

import { getI18nProps } from '../utils/getI18nProps'
import { addErrorHandler, start } from 'single-spa'
import {
  devTools,
  registerLoginErrorApp,
  registerOrgLookupApp,
  registerUniversalLoginApp
} from '../registration'
import { handleError } from '../utils'

getI18nProps()
  .then((i18n) => {
    const globalCustomProps = {
      i18n,
      banquetStartTime: new Date().getTime().toString()
    }

    devTools(globalCustomProps)
    registerLoginErrorApp(globalCustomProps)
    registerOrgLookupApp(globalCustomProps)
    registerUniversalLoginApp(globalCustomProps)
    addErrorHandler(handleError)
    start({ urlRerouteOnly: true })
  })
  .catch((_) => {
    //TODO: hook up to sentry
  })
