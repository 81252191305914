export enum spaList {
  'SPA-LOGIN-ERROR' = 'spa-login-error',
  'SPA-SSO-LOOKUP' = 'spa-sso-lookup',
  'SPA-TOAST-UNIVERSAL-LOGIN' = 'spa-toast-universal-login'
}

export const spaRoutes = {
  'spa-login-error': '/error',
  'spa-sso-lookup': '/sso-lookup',
  'spa-toast-universal-login': '/u/login'
}
